:root {
  --blue: #1a8ddc;
  --blue_fileTag: #3dd1fd;
}

html,
body {
  font-family: 'Avenir LT Std', 'Nunito Sans', Lato, sans-serif;
  font-size: 1em;
  height: 100%;
}

#root {
  height: 100%;
}

.path_item {
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .path_item {
    white-space: nowrap;
  }
}

/*Document body*/
#body {
  margin-left: -5px;
  margin-bottom: 30px;
}

ul {
  /*position: relative;*/
  padding-left: 22px;
}

@media only screen and (max-width: 600px) {
  ul {
    padding-left: 0px;
  }
}

.node_item {
  position: relative;
}

@media only screen and (max-width: 600px) {
  .node_item {
    padding-left: 10px;
  }
}

.nestable-item,
.nestable-item-copy,
.nestable-item .nestable-list,
.nestable-item-copy .nestable-list {
  margin: 0;
}

/*to change color of loading links in editor*/
.loading-color {
  -webkit-animation: color-change 2s infinite;
  -moz-animation: color-change 2s infinite;
  -o-animation: color-change 2s infinite;
  -ms-animation: color-change 2s infinite;
  animation: color-change 2s infinite;
  display: inline-block;
}

@-webkit-keyframes color-change {
  0% {
    color: var(--blue_fileTag);
  }

  50% {
    color: var(--blue);
  }

  100% {
    color: var(--blue_fileTag);
  }
}

@-moz-keyframes color-change {
  0% {
    color: var(--blue_fileTag);
  }

  50% {
    color: var(--blue);
  }

  100% {
    color: var(--blue_fileTag);
  }
}

@-ms-keyframes color-change {
  0% {
    color: var(--blue_fileTag);
  }

  50% {
    color: var(--blue);
  }

  100% {
    color: var(--blue_fileTag);
  }
}

@-o-keyframes color-change {
  0% {
    color: var(--blue_fileTag);
  }

  50% {
    color: var(--blue);
  }

  100% {
    color: var(--blue_fileTag);
  }
}

@keyframes color-change {
  0% {
    color: var(--blue_fileTag);
  }

  50% {
    color: var(--blue);
  }

  100% {
    color: var(--blue_fileTag);
  }
}
