body {
  background-color: #fff;
}

#loading {
  padding-top: 25vh;
  text-align: center;
  height: 100vh;
  z-index: 2000;
  position: fixed;
  width: 100vw;
  background-color: #fff;
  left: 0;
  top: 0;
}

.inner {
  margin-left: -15px;
  margin-right: -15px;
}

#loading h1 {
  font-weight: 100;
  color: rgb(68, 84, 106);
  display: inline-block;
  font-size: 70px;
  margin: 0px;
  padding-top: 3px;
  margin-left: 10px;
}

#loading h2 {
  font-weight: 100;
  font-size: 40px;
  color: rgb(68, 84, 106);
}

#loading h2 span {
  color: rgb(236, 93, 87);
}

#loading h3 {
  font-weight: 100;
  font-size: 20px;
  color: rgb(68, 84, 106);
  position: relative;
}

#loading .loader {
  position: absolute;
  top: -40px;
  left: calc(50vw - 100px);
}

#loading .logo {
  width: 70px;
  vertical-align: top;
}
